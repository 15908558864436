import React from 'react';
import { IconDropDown } from '@assets/icons';
import { Select } from 'antd';
import PropTypes from 'prop-types';

function MySelect({ listOptions, isDisable, ...props }) {
  return (
    <Select
      disabled={isDisable}
      suffixIcon={<IconDropDown className="anticon" />}
      {...props}
    >
      {listOptions?.map((item) => {
        return item?.value ? (
          <Select.Option
            key={item?.value}
            value={item?.value}
            knName={item?.baseNmKn}
          >
            {item?.label}
          </Select.Option>
        ) : null;
      })}
    </Select>
  );
}

export default MySelect;

MySelect.propTypes = {
  listOptions: PropTypes.array,
  isDisable: PropTypes.bool,
};
