/* eslint-disable react/jsx-filename-extension */

/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import DetailAttenDance from '@pages/attendances/Detail';
import { PATH_URL_PC } from './pathURL';

const SlotsMaster = React.lazy(() => import('@pages/master/SlotsMaster'));
const BaseMaster = React.lazy(() => import('@pages/master/BaseMaster'));
const CarMaster = React.lazy(() => import('@pages/master/CarMaster'));
const LinkMaster = React.lazy(() => import('@pages/master/LinkMaster'));
const UserMaster = React.lazy(() => import('@pages/master/UserMaster'));
const CustomerMaster = React.lazy(() => import('@pages/master/CustomerMaster'));
const CompanyMaster = React.lazy(() => import('@pages/master/CompanyMaster'));
const DriverMaster = React.lazy(() => import('@pages/master/DriverMaster'));
const Home = React.lazy(() => import('@pages/home/Home'));
const Login = React.lazy(() => import('@pages/login'));
const Account = React.lazy(() => import('@pages/account'));
const OrderList = React.lazy(() => import('@pages/orders/OrderList'));
const OrderDetail = React.lazy(() => import('@pages/orders/OrderDetail'));
const OrderCreate = React.lazy(() => import('@pages/orders/OrderCreate'));
const OrderUpdate = React.lazy(() => import('@pages/orders/OrderUpdate'));
const RegularList = React.lazy(() => import('@pages/regular/RegularList'));
const RegularDetail = React.lazy(() => import('@pages/regular/RegularDetail'));
const RegularCreate = React.lazy(() => import('@pages/regular/RegularCreate'));
const RegularUpdate = React.lazy(() => import('@pages/regular/RegularUpdate'));
const LadingList = React.lazy(() => import('@pages/lading/LadingList'));
const LadingDetail = React.lazy(() => import('@pages/lading/Detail'));
const LadingCreate = React.lazy(() => import('@pages/lading/LadingCreate'));
const CalculationList = React.lazy(() =>
  import('@pages/calculation/CalculationList'),
);
const CalculationReport = React.lazy(() =>
  import('@pages/calculation/CalculationReport'),
);
const CalculationDetail = React.lazy(() =>
  import('@pages/calculation/CalculationDetail'),
);
const AssignCreate = React.lazy(() => import('@pages/assign/AssignCreate'));
const WorkingCar = React.lazy(() => import('@pages/workingCar/WorkingCar'));
const OperationManagement = React.lazy(() =>
  import('@pages/operationManagement/OperationManagement'),
);
const AttenDanceList = React.lazy(() =>
  import('@pages/attendances/AttendancesList'),
);
const Chart = React.lazy(() => import('@pages/chart/Charts'));
const Maintenance = React.lazy(() => import('@pages/maintenance/index'));
const OrderBarcodeList = React.lazy(() =>
  import('@pages/orderBarcode/OrderBarcodeList'),
);
const OrderBarcodeCreate = React.lazy(() =>
  import('@pages/orderBarcode/OrderBarcodeCreate'),
);

const OrderBarcodeDetail = React.lazy(() =>
  import('@pages/orderBarcode/OrderBarcodeDetail'),
);
const routes = [
  // route pc
  {
    path: PATH_URL_PC.HOME,
    element: <Home />,
  },
  {
    path: PATH_URL_PC.LOGIN,
    element: <Login />,
  },
  {
    path: PATH_URL_PC.MAINTENANCE,
    element: <Maintenance />,
  },
  {
    path: PATH_URL_PC.ACCOUNT,
    element: <Account />,
    breadcrumb: 'パスワード変更',
  },
  {
    path: PATH_URL_PC.ORDER_LIST,
    element: <OrderList />,
    breadcrumb: '注文一覧',
  },
  {
    path: PATH_URL_PC.ORDER_DETAIL,
    element: <OrderDetail />,
    breadcrumb: '注文詳細',
  },
  {
    path: PATH_URL_PC.ORDER_CREATE,
    element: <OrderCreate />,
    breadcrumb: '新規配送依頼',
  },
  {
    path: PATH_URL_PC.ORDER_UPDATE,
    element: <OrderUpdate />,
    breadcrumb: '配送依頼',
  },
  {
    path: PATH_URL_PC.REGULAR_LIST,
    element: <RegularList />,
    breadcrumb: '定期配送一覧',
  },
  {
    path: PATH_URL_PC.REGULAR_DETAIL,
    element: <RegularDetail />,
    breadcrumb: '定期集配詳細',
  },
  {
    path: PATH_URL_PC.REGULAR_CREATE,
    element: <RegularCreate />,
    breadcrumb: '新規定期配送依頼',
  },
  {
    path: PATH_URL_PC.REGULAR_UPDATE,
    element: <RegularUpdate />,
    breadcrumb: '定期配送依頼',
  },
  {
    path: PATH_URL_PC.BASE_MASTER,
    element: <BaseMaster />,
    breadcrumb: '拠点マスタ',
  },
  {
    path: PATH_URL_PC.CAR_MASTER,
    element: <CarMaster />,
    breadcrumb: '車両マスタ',
  },
  {
    path: PATH_URL_PC.LINK_MASTER,
    element: <LinkMaster />,
    breadcrumb: 'リンクマスタ',
  },
  {
    path: PATH_URL_PC.USER_MASTER,
    element: <UserMaster />,
    breadcrumb: 'ユーザーマスタ設定',
  },
  {
    path: PATH_URL_PC.CUSTOMER_MASTER,
    element: <CustomerMaster />,
    breadcrumb: '顧客マスタ',
  },
  {
    path: PATH_URL_PC.DRIVER_MASTER,
    element: <DriverMaster />,
    breadcrumb: '配送員マスタ',
  },
  {
    path: PATH_URL_PC.COMPANY_MASTER,
    element: <CompanyMaster />,
    breadcrumb: '運送会社マスタ',
  },
  {
    path: PATH_URL_PC.SLOTS_MASTER,
    element: <SlotsMaster />,
    breadcrumb: '配送枠マスタ',
  },
  {
    path: PATH_URL_PC.LADIND_LIST,
    element: <LadingList />,
    breadcrumb: '運行一覧',
  },
  {
    path: PATH_URL_PC.LADIND_DETAIL,
    element: <LadingDetail />,
    breadcrumb: '運行詳細',
  },
  {
    path: PATH_URL_PC.LADING_CREATE,
    element: <LadingCreate />,
    breadcrumb: '運行ID生成',
  },
  {
    path: PATH_URL_PC.CALCULATION_LIST,
    element: <CalculationList />,
    breadcrumb: '精算一覧',
  },
  {
    path: PATH_URL_PC.ATTENDANCE_LIST,
    element: <AttenDanceList />,
    breadcrumb: '勤怠管理',
  },
  {
    path: PATH_URL_PC.ATTENDANCE_DETAIL,
    element: <DetailAttenDance />,
    breadcrumb: '勤怠詳細一覧',
  },
  {
    path: PATH_URL_PC.CALCULATION_DETAIL,
    element: <CalculationDetail />,
    breadcrumb: '精算注文詳細',
  },
  // route mobile
  {
    path: PATH_URL_PC.CALCULATION_REPORT,
    element: <CalculationReport />,
    breadcrumb: '精算金額内訳',
  },
  {
    path: PATH_URL_PC.ASSIGN_CREATE,
    element: <AssignCreate />,
    breadcrumb: '配送設定',
  },
  {
    path: PATH_URL_PC.OPMONITOR,
    element: <WorkingCar />,
    breadcrumb: '稼働車両状況',
  },
  {
    path: PATH_URL_PC.OP_MANAGEMENT,
    element: <OperationManagement />,
    breadcrumb: '運行管理',
  },
  {
    path: PATH_URL_PC.CHART,
    element: <Chart />,
    breadcrumb: '分析',
  },
  {
    path: PATH_URL_PC.ORDER_BARCODE_LIST,
    element: <OrderBarcodeList />,
    breadcrumb: '荷物情報照会',
  },
  {
    path: PATH_URL_PC.ORDER_BARCODE_CREATE,
    element: <OrderBarcodeCreate />,
    breadcrumb: '荷物情報照会新規登録',
  },
  {
    path: PATH_URL_PC.ORDER_BARCODE_DETAIL,
    element: <OrderBarcodeDetail />,
    breadcrumb: '荷物情報照会詳細',
  },
  // route mobile
];

export default routes;
