import { useMutation, useQuery } from 'react-query';
import driverApi from '@services/apis/driver';

const useGetDrivers = (params) =>
  useQuery(['get_drivers', params], () => driverApi.getDrivers(params), {
    staleTime: Infinity,
  });
export const useGetDriversList = (params) =>
  useQuery(['get_drivers_list', params], () =>
    driverApi.getDriversList(params),
  );
export const useGetDriverDetail = (id) =>
  useQuery(['get_driver_detail', id], () => driverApi.getDriverDetail(id), {
    staleTime: Infinity,
    enabled: !!id,
    cacheTime: 0,
  });
export const usePostDriver = () =>
  useMutation((params) => driverApi.postDriver(params));

export const useUpdateDriver = () =>
  useMutation(({ params, id }) => driverApi.updateDriver(params, id));

export default useGetDrivers;
