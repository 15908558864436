export const PATH_URL_PC = {
  HOME: '/',
  LOGIN: '/login',
  MAINTENANCE: '/maintenance',
  ACCOUNT: '/account',
  ORDER_LIST: '/orders',
  ORDER_DETAIL: '/orders/:idOrder',
  ORDER_CREATE: '/orders/create',
  ORDER_UPDATE: '/orders/:idOrder/update',
  REGULAR_LIST: '/regular-orders',
  REGULAR_DETAIL: '/regular-orders/:idOrder',
  REGULAR_CREATE: '/regular-orders/create',
  REGULAR_UPDATE: '/regular-orders/:idOrder/update',
  BASE_MASTER: '/base_list',
  USER_MASTER: '/user_master',
  CAR_MASTER: '/car_list',
  LINK_MASTER: '/link_list',
  COMPANY_MASTER: '/company_list',
  CUSTOMER_MASTER: '/customer_list',
  DRIVER_MASTER: '/driver_master',
  SLOTS_MASTER: '/slot_list',
  LADIND_LIST: '/services',
  LADIND_DETAIL: '/services/:serviceId',
  LADING_CREATE: '/services/create',
  CALCULATION_LIST: '/calculation',
  CALCULATION_REPORT: '/calculation/report',
  CALCULATION_DETAIL: '/calculation/report/:orderId',
  ASSIGN_CREATE: '/assign',
  OPMONITOR: '/op_monitor',
  OP_MANAGEMENT: '/op_management',
  ATTENDANCE_LIST: '/attendances',
  ATTENDANCE_DETAIL: '/attendances/:attendanceId',
  CHART: '/chart',
  ORDER_BARCODE_LIST: '/order-barcode',
  ORDER_BARCODE_DETAIL: '/order-barcode/:bcOrderSeq',
  ORDER_BARCODE_CREATE: '/order-barcode/create',
};
export const PATH_URL_MOBILE = {
  LOGIN: '/mobile/login',
  DASHBOARD: '/mobile/dashboard',
};
